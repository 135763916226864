import React from "react";

class Button extends React.Component{

    render(){
        return(
            <div class='myButton'>
                <input type="button" value={this.props.value} href={this.props.href}/>
            </div>
        );

    }

}

export default Button;