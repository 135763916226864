import React from "react";
import Button from "./Button";
import MouseScroll from "./MouseScroll";


class Cover extends React.Component{


    render(){
        return(
            <section class="coverwrapper">
                <div class="cover"></div>
                <MouseScroll></MouseScroll>
            </section>

        );
    }
}

export default Cover;